.toc-sidebar {
  font-size: .8em;
  opacity: .8;
}

.toc-menu {
  border-width: 0 0 0 0.25rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  .subheading-list {
    margin-left: 0.5em;
  }
}

.toc-title {
  font-size: inherit;
  margin: 0;
  padding: 0;
  font-family: var(--heading);
  color: var(--aluminum-5);
  letter-spacing: .05em;
  font-weight: var(--weight-bold);
  text-transform: uppercase;
}

.toc .toc-menu li {
  margin: 0.5rem 0 0;
  padding-top: var(--xs);
  padding-bottom: var(--xs);
  padding-right: var(--sm);
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--sans-serif);
  font-weight: var(--weight-bold);
  font-size: var(--md);

  &.subheading-item {
    font-weight: var(--weight-normal);
    margin: .25rem 0 0;
  }
}

.toc a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--core-violet-2);
  }
}

.toc .toc-menu li a.toc-active {
  color: var(--core-violet-4);
}
