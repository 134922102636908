@font-face {
  font-family: "Silka";
  src: url("../fonts/silka-regular-webfont.eot");
  src: url("../fonts/silka-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/silka-regular-webfont.woff2") format("woff2"),
    url("../fonts/silka-regular-webfont.woff") format("woff"),
    url("../fonts/silka-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka-medium-webfont.eot");
  src: url("../fonts/silka-medium-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/silka-medium-webfont.woff2") format("woff2"), url("../fonts/silka-medium-webfont.woff") format("woff"),
    url("../fonts/silka-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka-semibold-webfont.eot");
  src: url("../fonts/silka-semibold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/silka-semibold-webfont.woff2") format("woff2"),
    url("../fonts/silka-semibold-webfont.woff") format("woff"),
    url("../fonts/silka-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: 600;
}

body {
  color: var(--color-text);
  font-family: var(--sans-serif);
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading);
  font-weight: var(--weight-medium);
  line-height: 1.2;
  margin-bottom: var(--md);
}

h2,
h3,
h4,
h5,
h6 {
  padding-top: var(--lg);
  margin-top: var(--lg);
}

h1,
h2 {
  padding-top: 0.75em;
  border-bottom: solid 1px var(--aluminum-4);
}

h1 {
  font-size: 1.8em;
  padding-bottom: 0.4em;
}

h2 {
  font-size: 1.6em;
  padding-bottom: 0.3em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: .9em;
}

code {
  background-color: #f6f6f7;
  border-radius: .2em;
  padding: .2em .4em;
}

p,
ul,
ol,
dl,
figure {
  margin-bottom: var(--sm);
  margin-top: var(--sm);
}

pre,
table,
aside {
  margin-bottom: var(--lg);
  margin-top: var(--lg);
}

a {
  color: var(--blue);

  .admonitionblock & {
    color: inherit;
  }
}

pre,
code {
  font-family: var(--monospace);
  font-size: .95em;
}

thead {
  font-family: var(--heading);
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: .05em;
  color: var(--aluminum-5);
  border-bottom: 1px solid var(--aluminum-3);
}

th {
  text-align: inherit;
}

td,
th {
  padding: var(--xs);
}

td > .tableblock {
  margin: 0;
}

ol,
ul {
  padding-left: var(--lg);
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: circle;
}

dl {
  border: 1px solid var(--aluminum-3);
  border-radius: 2px;
  display: inline-block;
  padding: var(--md);
}

dt {
  font-weight: var(--weight-medium);

  &::after {
    content: ":";
  }
}

dd {
  margin-left: 0;

  + dt {
    border-top: 1px solid var(--aluminum-3);
    margin-top: var(--sm);
    padding-top: var(--sm);
  }
}

blockquote {
  margin-left: 2rem;

  & + .attribution {
    text-align: right;
  }
}

.strong {
  font-weight: var(--weight-medium);
}

.strikethrough {
  text-decoration: line-through;
}
