:root {
  --core-gray: #f8f8fa;
  --core-violet-1: #9aa2e7;
  --core-violet-2: #6d79e9;
  --core-violet-3: #6472e7;
  --core-violet-4: #4e5ee4;
  --core-violet-6: #282846;
  --robust-blue-1: #a1b1b8;
  --robust-blue-2: #6b8a99;
  --robust-blue-3: #506773;
  --robust-blue-4: #32444d;
  --robust-blue-5: #272f33;
  --future-green-1: #aaf2cb;
  --future-green-2: #33cc7a;
  --future-green-3: #17bc65;
  --future-green-4: #0e8c48;
  --future-green-5: #174d30;
  --steel-1: #6b6c6d;
  --steel-2: #58595a;
  --steel-3: #3a3b3c;
  --steel-4: #3D3D58;
  --steel-5: #121314;
  --aluminum-0: #f5f6fe;
  --aluminum-1: #f9fafb;
  --aluminum-2: #f4f5f6;
  --aluminum-3: #e8e9ea;
  --aluminum-4: #cacbcc;
  --aluminum-5: #989a9b;
  --yellow-3: #f2be24;
  --viridian-3: #00b49d;
  --teal-3: #00b5d1;
  --navy-3: #178bea;
  --indigo-3: #5e66f9;
  --violet-3: #9a63f9;
  --red-3: #d1344e;
  --blue: #196BF7;
  --tertiary: #fff;

  --color-text: #282846;
  --color-text-light: #555566;
  --color-navbar-text: #282846;

  --border-radius: .3rem;

  --heading: "Silka", sans-serif;
  --sans-serif: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
  --monospace: "Roboto Mono", monospace;

  --weight-normal: 400;
  --weight-medium: 500;
  --weight-bold: 600;

  --header-height: 66px;
  --navbar-height: 70px;
  --footer-height: 361px;
  --nav-item-height: 32px;
  --xs: 5px;
  --sm: 10px;
  --md: 15px;
  --lg: 20px;
  --xl: 40px;
  --input-md: 30px;

  --transition-speed-sm: 0.15s;
  --transition-speed-md: 0.3s;
  --transition-timing: cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

// Minimum screen size for mobile is 320px
$mobile-breakpoint: 1000px;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1160px;

$header-height: 66px;
$footer-height: 361px;
$nav-width: 300px;

$xs: 5px;
$sm: 10px;
$md: 15px;
$lg: 20px;
$xl: 40px;
