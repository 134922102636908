html {
  -ms-text-size-adjust: 100;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

details,
main,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;

  &:hover,
  &:active {
    outline: 0;
  }
}

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure,
blockquote {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  display: block;
  line-height: 1.4;
  margin: 0;
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  background: transparent;
  box-shadow: none;
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input,
textarea {
  -webkit-appearance: none;
  background: transparent;
  border: 0;

  &[type='radio'] {
    -webkit-appearance: radio;
    cursor: pointer;
  }
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

button,
input,
select[multiple],
textarea {
  background-image: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

iframe {
  border: 0;
}

address {
  font-style: normal;
}

input[disabled] textarea[disabled] {
  cursor: not-allowed;
}

input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
