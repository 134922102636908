.alert {
  p {
    font-family: var(--heading);
    color: inherit;
    font-size: 1rem;
  }
}

.admonitionblock,
.alert {
  max-width: 90vw;
  background-color: white;
  border: solid #ddd;
  border-radius: var(--border-radius);
  border-width: 1px 1px 1px 6px;
  font-family: var(--sans-serif);
  font-weight: var(--weight-normal);
  color: var(--color-text-light);
  font-size: 0.9rem;
  text-align: center;
  line-height: 1.5;
  padding: 1rem .9rem;
  margin: 1.5rem 0;
  overflow-wrap: break-word;

  table {
    padding: 0;
    margin: 0;
    th,
    td {
      padding: 0;
      margin: 0;
      word-wrap: break-word;
    }
  }

  .content {
    max-width: 82vw;
    padding-left: .25rem;
    font-size: 1rem;
    text-align: left;
    word-wrap: break-word;
  }

  &::before { 
    display: block;
    font-size: 0.9em;
    font-weight: var(--weight-bold);
    text-align: left;
    text-transform: uppercase;
    padding-left: .25rem;
    margin-bottom: .5rem;
  }

  &.note {
    border-left-color: var(--core-violet-4);

    &::before { 
      content: "note";
      color: var(--core-violet-4);
    }
  }

  &.tip {
    border-left-color: var(--teal-3);

    &::before { 
      content: "tip";
      color: var(--teal-3);
    }
  }

  &.warning, &.caution, &.important {
    border-left-color: #F79F1F;

    &::before { 
      color: #f79f1f;
      padding-left: 1.5em;
      margin-left: .4em;
      background-image: url(../images/icons/warning.svg);
      background-repeat: no-repeat;
      background-position: left;
      background-origin: border-box;
    }
  }

  &.warning::before {
    content: "warning";
  }

  &.caution::before {
    content: "caution";
  }

  &.important::before {
    content: "important";
  }
}
