footer.footer {
  font-size: 0.7rem;
  background-color: #fff;
  padding: 25px 2rem;
  color: #5d5d5d;
  border-top: solid 1px rgba(40, 40, 70, 0.10);
}

.footer a {
  color: rgba(40, 40, 70, 0.7);
  display: inline;
  margin-left: 7px;
  border-left: 1px solid rgba(40, 40, 70, 0.7);
  padding-left: 7px;
}
