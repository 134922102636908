.component-version {
  position: relative;
}

.btn-version {
  font-size: .85rem;
  font-weight: var(--weight-normal);
  min-height: 1.5rem;
  padding: 0 .5rem;
  margin: .4rem 0;
  border: 1px solid;
  border-radius: 1rem;

  &::after{
    display: inline-block;
    content: url(../images/icons/chevron.svg);
    width: .8rem;
    margin-left: .2rem;
  }

  &:hover,
  &:focus {
    color: var(--core-violet-4);
  }

  svg {
    height: var(--md);
    width: var(--md);
  }
}

.popover-container {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  padding-top: .5em;

  .btn-version:focus + &,
  &:focus-within {
    opacity: 1;
    pointer-events: auto;
  }
}

.popover {
  font-family: var(--sans-serif);
  font-size: .9em;
  background: var(--tertiary);
  border: 1px solid var(--aluminum-3);
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, .05) ;
  color: var(--steel-3);
  padding: .5em;
  margin: 0 var(--xs);
  width: 10em;
  user-select: none;

  p {
    margin: 0;
    padding: 0 .5em;
    font-weight: bold;
    font-size: .9em;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        display: block;
        padding: .2em .5em;

        &:hover {
          background-color: var(--aluminum-2);
        }
      }
    }
  }
}
